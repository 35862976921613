import { createStore } from "vuex"
import appModule from "../store"
import authModule from "../store/authModule.js"
import serverModule from "../store/serverModule.js"
import createPersistedState from "vuex-persistedstate"

export default function configureStore() {
  return createStore({
    modules: {
      app: appModule,
      auth: authModule,
      server: serverModule,
    },
    // plugins: [
    //   createPersistedState({
    //     key: "vuex_state", // You can change the key as needed
    //     paths: ["auth"], // List the module names you want to persist here
    //   }),
    // ],
  })
}
