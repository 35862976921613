import axios from "axios"
import router from "@/router"

const api = axios.create({
  baseURL: "http://172.104.205.70/api",
})

let isConfigured = false
const configureApi = store => {
  if (!isConfigured) {
    api.interceptors.request.use(config => {
      const token = store.state.auth.user // Replace 'auth' with the correct module name and 'user' with the token property
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    })

    api.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response && error.response.status === 401) {
          router.push({ name: "login" })
          return Promise.reject()
        }
        if (error.code === "ERR_NETWORK") {
          router.push({
            name: "status",
            state: {
              status: "NETWORK_ERROR",
              message: "Network error occurred",
            },
          })
          return Promise.reject()
        }

        return Promise.reject(error)
      }
    )

    isConfigured = true
  }
}

export default api
export { configureApi }
