import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/games/:gameId",
    name: "game",
    component: () => import("../views/GameView.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/TestView.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/PaymentView.vue"),
  },
  {
    path: "/panel",
    name: "panel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/PanelView.vue"),
  },
  {
    path: "/ooops",
    component: () => import("../views/StatusPageView.vue"),
    name: "status",
    props: {
      status: "NETWORK_ERROR",
      message: "Network error occurred",
    },
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/StatusPageView.vue"),
    props: {
      status: "404",
      message: "Page not found",
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
