<script setup>
import { defineProps } from "vue"
import { useRouter } from "vue-router"

const router = useRouter()
const props = defineProps(["game"])

const pushWithQuery = gameId => {
  router.push({
    name: "game",
    params: { gameId },
  })
}
</script>

<template>
  <Card @click="pushWithQuery(props.game._id)" class="game">
    <template #header>
      <div class="image-container">
        <img class="mt-1" :src="props.game.image" :alt="props.game.title" />
      </div>
    </template>
    <template #content>
      <h1 class="text-primary">
        {{ props.game.title }}
      </h1>
    </template>
  </Card>
</template>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 aspect ratio (9 divided by 16, then multiplied by 100) */
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* To maintain aspect ratio and cover the entire container */
}

.p-card {
  padding: 0;
  /* Set padding to 0 or adjust as per your requirement */
}

h1.text-primary {
  margin: 0;
}
</style>
