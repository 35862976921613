<script setup>
import { computed } from "vue"
import GameComponent from "../components/GameComponent.vue"
import { useStore } from "vuex"
import HomeSkeleton from '@/components/skeletons/HomeSkeleton.vue'

const store = useStore()
const areGamesFetched = computed(() => store.getters.areGamesFetched)

const games = computed(() => store.state.app.games)
</script>

<template>
  <div>
    <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
      <div v-if="areGamesFetched" class="grid">
        <div class="p-3 col-12 md:col-6 lg:col-3" v-for="game in games" :key="game._id">
          <GameComponent :game="game" />
        </div>
      </div>
      <HomeSkeleton v-else/>
    </div>
  </div>
</template>
