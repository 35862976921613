<script setup>
import { onMounted, computed } from "vue"
import { useStore } from "vuex"
import router from "@/router"
//import SideMenu from "./components/SideMenu.vue"

const store = useStore()

onMounted(() => {
  if (!store.getters.areGamesFetched) {
    store.dispatch("fetchGames").catch(err => {
      // router.go(0)
      console.log(err)
    })
  }
})

const isLoggedIn = computed(() => {
  return store.state.auth.user != null
})
</script>

<template>
  <nav class="card flex justify-content-between">
    <div class="flex gap-1">
      <Button label="/" @click="router.push({ name: 'home' })" outlined />
    </div>
    <div class="flex gap-1">
      <Button v-if="isLoggedIn" label="Panel" @click="router.push({ name: 'panel' })" outlined />
      <Button v-if="isLoggedIn" label="Sign Out" @click="store.dispatch('logOut')" outlined />
      <Button v-else label="Login" @click="router.push({ name: 'login' })" outlined />
    </div>
  </nav>
  <router-view />
  <!-- <SideMenu /> -->
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 100vw;
}

.highlight-text {
  color: var(--highlight-text-color);
}
</style>
