import api from "../api.js"

const state = {
  games: [],
  game: null,
  userInfo: null,
}

const getters = {
  areGamesFetched(state) {
    return !!state.games[0]
  },
}

const mutations = {
  setGames(state, data) {
    state.games = data
  },
  setGame(state, data) {
    state.game = data
  },
  setUserInfo(state, data) {
    state.userInfo = data
  },
}

const actions = {
  fetchGames({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get("games/all")
        commit("setGames", res.data)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },
  async fetchGameById({ commit }, { gameId }) {
    try {
      const res = await api.get(`games/${gameId}`)
      commit("setGame", res.data)
    } catch (error) {
      // console.error(error)
    }
  },
  getUserInfo({ commit }) {
    api.get("/users/getUserInfo").then(({ data }) => commit("setUserInfo", data))
  },
  removeServer({commit}, ip) {
    return api
      .post("/server/deleteServer", {
        serverId: ip,
      })
      .then(({ data }) => commit("setUserInfo", data))
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
