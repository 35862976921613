import api from "../api.js"
import router from "@/router"

export default {
  state: {
    label: "",
    region: "",
    plan: {},
    config: "",
  },
  mutations: {
    setServerLabel(state, label) {
      state.label = label
    },
    setServerRegion(state, region) {
      state.region = region
    },
    setServerPlan(state, plan) {
      state.plan = plan
    },
    setServerConfig(state, config) {
      state.config = config
    },
  },
  getters: {
    isServerComplete(state) {
      return state.region && state.plan && state.label
    },
    missingFields(state) {
      const missing = []
      if (!state.label) missing.push("Label")
      if (!state.region) missing.push("Region")
      if (!state.plan) missing.push("Plan")
      return missing
    },
  },
  actions: {
    getServerRegions() {
      return api.get("/server/serverRegions")
    },
    getServerTypes() {
      return api.get("/server/serverTypes")
    },
    createServer({ state, getters }, gameData) {
      if (getters.isServerComplete) {
        api
          .post("/server/rentServer", {
            ...gameData,
            label: state.label,
            region: state.region.id,
            plan: state.plan.id,
          })
          .then(async res => {
            console.log("Responded with ",res.data)
            api
              .post("/users/initGame", {id:res.data})
              .then(_ => console.log("Resolved InitGame"))
              .catch(e => console.log("FUCKED", e))

            router.push({ name: "panel" })
          })
        //TODO focus fields if not filled
      }
    },
    setup({}, id) {
      api
        .post("/users/initGame", { id: res })
        .then(_ => console.log("Resolved InitGame"))
        .catch(e => console.log("FUCKED", e))
      // return api.post("/server/serverSetup/" + ip)
    },
    addMod({}, ip) {
      return api.post("/server/addMod/" + ip)
    },
  },
}
