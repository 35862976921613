import PrimeVue from "primevue/config"
import Button from "primevue/button"
import Card from "primevue/card"
import InputText from "primevue/inputtext"
import Skeleton from "primevue/skeleton"
import Tooltip from "primevue/tooltip"
import ProgressSpinner from 'primevue/progressspinner'
import BlockUI from 'primevue/blockui'
import BadgeDirective from 'primevue/badgedirective'

//theme
import "primevue/resources/primevue.min.css"
import "primevue/resources/themes/md-dark-indigo/theme.css"
import "/node_modules/primeflex/primeflex.css"
import "primeicons/primeicons.css"

export default function configurePrimeVue(app) {
  app.use(PrimeVue);

  //Components
  app.component("Card", Card)
  app.component("Button", Button)
  app.component("InputText", InputText)
  app.component("Skeleton", Skeleton)
  app.component("ProgressSpinner", ProgressSpinner)
  app.component("BlockUI", BlockUI)
  
  //Directives
  app.directive("tooltip", Tooltip)
  app.directive("badge", BadgeDirective)
}
