import { createApp } from "vue"
import App from "./App.vue"
import router from "@/router"
import { configureApi } from "./api"
import configurePrimeVue from "./plugins/configurePrimeVue.js";
import configureStore from "./plugins/configureStore.js";


const app = createApp(App)
const store = configureStore()

app.use(router)
app.use(store)

configurePrimeVue(app);
configureApi(store)


app.mount("#app")
