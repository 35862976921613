// vuex/auth.js
import { reactive } from "vue"
import api from "../api"
import router from "@/router"

const state = reactive({
  user: null,
})

const mutations = {
  setUser(state, user) {
    state.user = user
  },
}

const actions = {
  logIn({ commit }, token) {
    // TODO Perform the login process
    // For example, send a request to the server to authenticate the user
    // Once the user is authenticated, update the state
    commit("setUser", token)
    // console.log(history);
    // router.go(-1) //When login exchange is properly implemented this should work to get user back to page he was redirected from to login, or where he was before he clicked login
  },
  logOut({ commit }) {
    // TODO check this might be done?!
    // Perform the logout process
    // For example, clear the user's session or access token
    // Once the user is logged out, update the state
    commit("setUser", null)
  },
}

export default {
  state,
  actions,
  mutations,
}
